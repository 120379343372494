// DEV CHANGES ON 4 DESEMBER 2024

import React from 'react';
import styles from './FilterManifestBrankas.module.css';

import { Row, Col } from 'antd';
import { Form, Field } from 'react-final-form';
import RadioField from 'components/RadioField';
import Button from 'components/Button';
import CardMaterial from 'materials/CardMaterial';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import { FilterManifestBrankasProps } from '../../container';
import { SelectOptionsInterface } from 'interface/general';

type FilterComponentManifestBrankasProps = {
  filterManifestBrankas: FilterManifestBrankasProps;
  handleChangeFilter: (name: string, value: any) => void;
  handleResetFilter: () => void;
  isLoadingCustomers: boolean;
  dataCustomers: SelectOptionsInterface[];
  handleSubmitFilter: (value: FilterManifestBrankasProps) => void;
};

export default function FilterManifestBrankas({
  filterManifestBrankas,
  handleResetFilter,
  handleChangeFilter,
  isLoadingCustomers = false,
  dataCustomers,
  handleSubmitFilter,
}: FilterComponentManifestBrankasProps) {
  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>MANIFEST BRANKAS</b>}
      style={{ borderRadius: '10px' }}
    >
      <Form
        initialValues={{
          ...filterManifestBrankas,
        }}
        validate={values => {
          const errors = {} as any;
          if (!values.customer) {
            errors.customer = 'Pelanggan wajib diisi';
          }
          if (
            !values.periodeManifest ||
            values.periodeManifest.length !== 2 ||
            !values.periodeManifest[0] ||
            !values.periodeManifest[1]
          ) {
            errors.periodeManifest = 'Periode Manifest wajib dipilih';
          }
          return errors;
        }}
        onSubmit={handleSubmitFilter}
      >
        {({ values, valid, handleSubmit, form }) => (
          <div className={styles.wrapperFilter}>
            <div className={styles.wrapperItemFilter}>
              <Field name="typeManifest">
                {({ input }) => (
                  <RadioField
                    data={[
                      { value: 'Success', label: 'Sukses' },
                      { value: 'Return', label: 'Return' },
                    ]}
                    onChange={value => {
                      handleChangeFilter('typeManifest', value);
                    }}
                    value={input.value}
                  />
                )}
              </Field>
              <Row gutter={24}>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="customer">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Pelanggan"
                        placeholder="Pilih Pelanggan"
                        value={input.value}
                        onChange={value => {
                          handleChangeFilter('customer', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.touched && meta.error ? 'error' : 'validating'
                        }
                        errorMessage={meta.touched && meta.error && meta.error}
                        loading={isLoadingCustomers}
                        data={dataCustomers}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="periodeManifest">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Periode Tanggal"
                        firstPlaceholder="Dari tanggal"
                        secondPlaceholder="Sampai tanggal"
                        format="YYYY-MM-DD"
                        onBlur={input.onBlur}
                        onChange={value => {
                          handleChangeFilter('periodeManifest', value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                        type="range"
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </div>
            <div className={styles.actionFilter}>
              <Button
                text="Reset"
                variant="danger"
                className={styles.actionWidth}
                disabled={!valid}
                onClick={() => {
                  handleResetFilter();
                  form.restart();
                }}
              />
              <Button
                text="Cari"
                className={styles.actionWidth}
                disabled={!valid}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </Form>
    </CardMaterial>
  );
}
