// DEV CHANGES ON 4 DESEMBER 2024

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { SelectOptionsInterface } from 'interface/general';
import {
  getBundleCode,
  postManifestBrankas,
} from 'services/ManifestSaveBrankas';
import FormManifestBrankas from '../components/FormManifestBrankas';
import ModalStatus from 'materials/ModalStatus';
import { DataManifestBrankasProps } from '../Interface/manifest.interface';
import { getProductByCustomers } from 'services/GeneralService';

type ModalAddManifestBrankasProps = {
  isShowModalAdd: boolean;
  setIsShowModalAdd: () => void;
  setIsShowModalScan: (value: number) => void;
  dataManifestRetur: SelectOptionsInterface[];
  dataCustomers: SelectOptionsInterface[];
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  refetchTable: () => void;
};

export default function ModalAddManifestBrankas({
  isShowModalAdd,
  setIsShowModalAdd,
  dataManifestRetur,
  loadingManifestRetur,
  onClickAddRetur,
  dataCustomers,
  refetchTable,
  setIsShowModalScan,
}: ModalAddManifestBrankasProps) {
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);
  const formRef: any = React.useRef();

  const { isFetching: isLoadingBundle, refetch: refetchBundleCode } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataBundleCodeManifest'],
    queryFn: () => getBundleCode(),
    onSuccess(res) {
      formRef.current.change('bundleCode', res.data);
    },
  });

  const { data: dataProducts, isFetching: isLoadingProducts } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataProductsForm', { id: selectedCustomerId }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    enabled: !!selectedCustomerId,
    refetchOnWindowFocus: false,
  });

  const { mutate: submitManifestBrankas, isLoading: isLoadingSubmitForm } =
    useMutation(postManifestBrankas, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
            handleOke: () => setIsShowModalScan(res.data),
          });
          setIsShowModalAdd();
          refetchTable();
          refetchBundleCode();
        } else {
          if (
            res.response?.data?.Message === 'BundleCode sudah pernah diinput.'
          ) {
            refetchBundleCode();
          }
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleSubmit = (values: DataManifestBrankasProps) => {
    submitManifestBrankas(values);
  };

  const listProducts =
    (dataProducts &&
      dataProducts?.data?.data?.map((item: any) => ({
        id: item.id,
        name: item.name,
      }))) ||
    [];
  return (
    <FormManifestBrankas
      dataCustomers={dataCustomers}
      dataManifestRetur={dataManifestRetur}
      formRef={formRef}
      handleSubmit={handleSubmit}
      isLoadingForm={isLoadingBundle || isLoadingSubmitForm}
      isLoadingProducts={isLoadingProducts}
      isShowModalAdd={isShowModalAdd}
      listProducts={listProducts}
      loadingManifestRetur={loadingManifestRetur}
      onClickAddRetur={onClickAddRetur}
      setIsShowModalAdd={setIsShowModalAdd}
      setSelectedCustomerId={setSelectedCustomerId}
    />
  );
}
