import * as ActionAddCustomReportCustomer from '../../../core/action/Customer/ActionAddCustomReportDataCustomer';
import * as ActionCustomDataReportCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionEditCustomReportCustomer from '../../../core/action/Customer/ActionEditCustomReportCustomer';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomDataReportCustomerComponent from '../../../components/Content/Customer/FormCustomDataReportCustomerComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  actionCustomDataReportCustomer: any;
  actionAddCustomReportCustomer: any;
  actionEditCustomReportCustomer: any;
  visible: any;
  jenisToggle: any;
  isLoading: any;
  GetCustomerName: any;
  GetHeader: any;
  GetFormat: any;
  GetPosition: any;
  GetValue: any;
  GetValueDefault: any;
  GetSourceTable: any;
  GetFormatType: any;
  GetSourceColumn: any;
  GetSourceColumnSec: any;
  GetListSourceColumn: any;
  GetListSourceColumnSec: any;
  GetFormatColumn: any;
  GetCharLength: any;
  GetHistoryType: any;
  GetDeliveryTo: any;
  GetSubColumn: any;
  GetSubColumnSec: any;
  GetListSubColumn: any;
  GetListSubColumnSec: any;
  GetReplaceSuccess: any;
  GetReplaceReturn: any;
  GetReplaceOnProcess: any;
  GetReplaceOnHold: any;
  GetCheckBoxPetik: any;
  GetCheckBoxOthers: any;
  LoadingCustomer: any;
  TypeLoadingCustomer: any;
}
interface IState {
  ValidateHeader: any;
  ValidateFormat: any;
  ValidatePosition: any;
  ValidateValueDefault: any;
  ValidateSourceTable: any;
  ValidateFormatType: any;
  ValidateSourceColumn: any;
  ValidateSourceColumnSec: any;
  ValidateFormatColumn: any;
  ValidateHistoryType: any;
  ValidateDeliveryTo: any;
  ValidateSubColumn: any;
  ValidateSubColumnSec: any;
  ValidateReplaceSuccess: any;
  ValidateReplaceReturn: any;
  ValidateReplaceOnProcess: any;
  ValidateReplaceOnHold: any;
}
// TODO : need to be refactored!!!

class FormCustomDataReportCustomerContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateHeader: null,
      ValidateFormat: null,
      ValidatePosition: null,
      ValidateValueDefault: null,
      ValidateSourceTable: null,
      ValidateFormatType: null,
      ValidateSourceColumn: null,
      ValidateSourceColumnSec: null,
      ValidateFormatColumn: null,
      ValidateHistoryType: null,
      ValidateDeliveryTo: null,
      ValidateSubColumn: null,
      ValidateSubColumnSec: null,
      ValidateReplaceSuccess: null,
      ValidateReplaceReturn: null,
      ValidateReplaceOnProcess: null,
      ValidateReplaceOnHold: null,
    };
    this.handleChangeHeader = this.handleChangeHeader.bind(this);
    this.handleChangeFormat = this.handleChangeFormat.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangeValueDefault = this.handleChangeValueDefault.bind(this);
    this.handleChangeSourceTable = this.handleChangeSourceTable.bind(this);
    this.handleChangeFormatType = this.handleChangeFormatType.bind(this);
    this.handleChangeSourceColumn = this.handleChangeSourceColumn.bind(this);
    this.handleChangeSourceColumnSec =
      this.handleChangeSourceColumnSec.bind(this);
    this.handleChangeCharLength = this.handleChangeCharLength.bind(this);
    this.handleChangeFormatColumn = this.handleChangeFormatColumn.bind(this);
    this.handleChangeHistoryType = this.handleChangeHistoryType.bind(this);
    this.handleChangeDeliveryTo = this.handleChangeDeliveryTo.bind(this);
    this.handleChangeSubColumn = this.handleChangeSubColumn.bind(this);
    this.handleChangeSubColumnSec = this.handleChangeSubColumnSec.bind(this);
    this.handleChangeReplaceSuccess =
      this.handleChangeReplaceSuccess.bind(this);
    this.handleChangeReplaceReturn = this.handleChangeReplaceReturn.bind(this);
    this.handleChangeReplaceOnProcess =
      this.handleChangeReplaceOnProcess.bind(this);
    this.handleChangeReplaceOnHold = this.handleChangeReplaceOnHold.bind(this);
    this.handleChangeCheckBoxPetik = this.handleChangeCheckBoxPetik.bind(this);
    this.handleChangeCheckBoxOthers =
      this.handleChangeCheckBoxOthers.bind(this);
    this.handleBlurSourceTable = this.handleBlurSourceTable.bind(this);
    this.handleBlurFormatType = this.handleBlurFormatType.bind(this);
    this.handleBlurSourceColumn = this.handleBlurSourceColumn.bind(this);
    this.handleBlurSourceColumnSec = this.handleBlurSourceColumnSec.bind(this);
    this.handleBlurHistoryType = this.handleBlurHistoryType.bind(this);
    this.handleBlurDeliveryTo = this.handleBlurDeliveryTo.bind(this);
    this.handleBlurSubColumn = this.handleBlurSubColumn.bind(this);
    this.handleBlurSubColumnSec = this.handleBlurSubColumnSec.bind(this);
    this.handleBlurFormat = this.handleBlurFormat.bind(this);
    this.handleGetListSourceColumn = this.handleGetListSourceColumn.bind(this);
    this.handleGetListSourceColumnSec =
      this.handleGetListSourceColumnSec.bind(this);
    this.handleGetListSubColumn = this.handleGetListSubColumn.bind(this);
    this.handleGetListSubColumnSec = this.handleGetListSubColumnSec.bind(this);
    this.handleResetFormat = this.handleResetFormat.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitCustomReport = this.handleSubmitCustomReport.bind(this);
  }

  handleChangeHeader(event: any) {
    // const RegexMinChar = /^\s+$/;
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomDataReportCustomer.setHeaderCdrc(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomDataReportCustomer.setHeaderCdrc(
        event.target.value
      );
    }
    if (event.target.value === '') {
      this.setState({
        ValidateHeader: 'error',
      });
    } else {
      this.setState({
        ValidateHeader: null,
      });
    }
  }

  handleChangePosition(event: any) {
    this.props.actionCustomDataReportCustomer.setPositionCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidatePosition: 'error',
      });
    } else {
      this.setState({
        ValidatePosition: null,
      });
    }
  }

  handleChangeFormat(event: any) {
    this.props.actionCustomDataReportCustomer.setFormatCdrc(event);
    if (event === '') {
      this.setState({
        ValidateFormat: 'error',
      });
    } else {
      this.setState({
        ValidateFormat: null,
      });
    }

    if (event !== 'Value') {
      this.props.actionCustomDataReportCustomer.clearOnClickValueCdrc();
      this.setState({
        ValidatePosition: null,
        ValidateValueDefault: null,
        ValidateSourceTable: null,
        ValidateFormatType: null,
        ValidateSourceColumn: null,
        ValidateSourceColumnSec: null,
        ValidateFormatColumn: null,
        ValidateHistoryType: null,
        ValidateDeliveryTo: null,
        ValidateSubColumn: null,
        ValidateSubColumnSec: null,
        ValidateReplaceSuccess: null,
        ValidateReplaceReturn: null,
        ValidateReplaceOnProcess: null,
        ValidateReplaceOnHold: null,
      });
    }
  }

  handleBlurFormat() {
    if (this.props.GetFormat === null) {
      this.setState({
        ValidateFormat: 'error',
      });
    } else {
      this.setState({
        ValidateFormat: null,
      });
    }
  }

  handleResetFormat() {
    this.setState({
      ValidateFormat: null,
    });
  }

  handleChangeValue() {
    this.props.actionCustomDataReportCustomer.setValueCdrc();
    this.setState({ ValidateValueDefault: null });
    this.props.actionCustomDataReportCustomer.clearOnClickValueCdrc();
    this.setState({
      ValidatePosition: null,
      ValidateValueDefault: null,
      ValidateSourceTable: null,
      ValidateFormatType: null,
      ValidateSourceColumn: null,
      ValidateSourceColumnSec: null,
      ValidateFormatColumn: null,
      ValidateHistoryType: null,
      ValidateDeliveryTo: null,
      ValidateSubColumn: null,
      ValidateSubColumnSec: null,
      ValidateReplaceSuccess: null,
      ValidateReplaceReturn: null,
      ValidateReplaceOnProcess: null,
      ValidateReplaceOnHold: null,
    });
  }

  handleChangeValueDefault(event: any) {
    const RegexMinChar = /^.{0,}$/;
    this.props.actionCustomDataReportCustomer.setValueDefaultCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateValueDefault: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateValueDefault: 'minchar',
      });
    } else {
      this.setState({
        ValidateValueDefault: null,
      });
    }
  }

  handleChangeSourceTable(event: any) {
    this.props.actionCustomDataReportCustomer.setSourceTableCdrc(event);
    this.props.actionCustomDataReportCustomer.clearSourceTableCdrc();
    if (event === '') {
      this.setState({
        ValidateSourceTable: 'error',
      });
    } else {
      this.setState({
        ValidateSourceTable: null,
      });
    }
  }

  handleBlurSourceTable() {
    if (this.props.GetSourceTable === null) {
      this.setState({
        ValidateSourceTable: 'error',
      });
    } else {
      this.setState({
        ValidateSourceTable: null,
      });
    }
  }

  handleChangeFormatType(event: any) {
    this.props.actionCustomDataReportCustomer.setFormatColumnCdrc(null);
    this.props.actionCustomDataReportCustomer.setFormatTypeCdrc(event);
    if (event === '') {
      this.setState({
        ValidateFormatType: 'error',
      });
    } else {
      this.setState({
        ValidateFormatType: null,
      });
    }
    if (event === 'Empty') {
      this.setState({
        ValidateFormatColumn: null,
      });
    }
    if (event === 'Imbuhan') {
      this.props.actionCustomDataReportCustomer.setFormatColumnCdrc(`'<value>`);
      this.setState({
        ValidateFormatColumn: null,
      });
    } else if (event === 'CombineColumn') {
      this.props.actionCustomDataReportCustomer.setFormatColumnCdrc(
        `<value1>(<value2>)`
      );
      this.setState({
        ValidateFormatColumn: null,
      });
    } else {
      this.setState({
        ValidateFormatColumn: null,
      });
    }
  }

  handleBlurFormatType() {
    if (this.props.GetFormatType === null) {
      this.setState({
        ValidateFormatType: 'error',
      });
    } else {
      this.setState({
        ValidateFormatType: null,
      });
    }
  }

  handleGetListSourceColumn() {
    if (this.props.GetSourceTable === 'Delivery') {
      this.props.actionCustomDataReportCustomer.getListColumnMasterCustomReportCustomer();
    } else if (this.props.GetSourceTable === 'DeliveryStatus') {
      this.props.actionCustomDataReportCustomer.getListColumnHistoryCustomReportCustomer();
    }
  }

  handleGetListSourceColumnSec() {
    if (this.props.GetSourceTable === 'Delivery') {
      this.props.actionCustomDataReportCustomer.getListColumnSecMasterCustomReportCustomer();
    } else if (this.props.GetSourceTable === 'DeliveryStatus') {
      this.props.actionCustomDataReportCustomer.getListColumnSecHistoryCustomReportCustomer();
    }
  }

  handleChangeSourceColumn(event: any) {
    this.props.actionCustomDataReportCustomer.setSourceColumnCdrc(event);
    this.props.actionCustomDataReportCustomer.clearSubColumnCdrc();
    if (event === '') {
      this.setState({
        ValidateSourceColumn: 'error',
      });
    } else {
      this.setState({
        ValidateSourceColumn: null,
      });
    }
    if (event !== 'DeliveryStatus') {
      this.props.actionCustomDataReportCustomer.clearFormatColumnCdrc();
    }
  }

  handleChangeSourceColumnSec(event: any) {
    this.props.actionCustomDataReportCustomer.setSourceColumnSecCdrc(event);
    this.props.actionCustomDataReportCustomer.clearSubColumnSecCdrc();
    if (event === '') {
      this.setState({
        ValidateSourceColumnSec: 'error',
      });
    } else {
      this.setState({
        ValidateSourceColumnSec: null,
      });
    }
  }

  handleBlurSourceColumn() {
    if (this.props.GetSourceColumn === null) {
      this.setState({
        ValidateSourceColumn: 'error',
      });
    } else {
      this.setState({
        ValidateSourceColumn: null,
      });
    }
  }

  handleBlurSourceColumnSec() {
    if (this.props.GetSourceColumnSec === null) {
      this.setState({
        ValidateSourceColumnSec: 'error',
      });
    } else {
      this.setState({
        ValidateSourceColumnSec: null,
      });
    }
  }

  handleChangeFormatColumn(event: any) {
    this.props.actionCustomDataReportCustomer.setFormatColumnCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateFormatColumn: 'error',
      });
    } else {
      this.setState({
        ValidateFormatColumn: null,
      });
    }
  }
  handleChangeCharLength(event: any) {
    // this.props.actionCustomDataReportCustomer.changeCharLengthCdrc(
    //   event.target.value.replace(/[^1-9]/g, '')
    this.props.actionCustomDataReportCustomer.changeCharLengthCdrc(event);
    // event.target.value.replace(/^[1-9][0-9]/g, '')
    // event.target.value.replace('^[0-9]*$')
    // event.target.value.replace(/^[0-9\b]+$/)
    // );
  }

  handleChangeHistoryType(event: any) {
    this.props.actionCustomDataReportCustomer.setHistoryTypeCdrc(event);
    if (event === '') {
      this.setState({
        ValidateHistoryType: 'error',
      });
    } else {
      this.setState({
        ValidateHistoryType: null,
      });
    }
  }

  handleBlurHistoryType() {
    if (this.props.GetHistoryType === null) {
      this.setState({
        ValidateHistoryType: 'error',
      });
    } else {
      this.setState({
        ValidateHistoryType: null,
      });
    }
  }

  handleChangeDeliveryTo(event: any) {
    this.props.actionCustomDataReportCustomer.setDeliveryToCdrc(event);
    if (event === '') {
      this.setState({
        ValidateDeliveryTo: 'error',
      });
    } else {
      this.setState({
        ValidateDeliveryTo: null,
      });
    }
  }

  handleBlurDeliveryTo() {
    if (this.props.GetDeliveryTo === null) {
      this.setState({
        ValidateDeliveryTo: 'error',
      });
    } else {
      this.setState({
        ValidateDeliveryTo: null,
      });
    }
  }

  handleGetListSubColumn() {
    this.props.actionCustomDataReportCustomer.getListColumnMasterCustomReportCustomer();
  }

  handleGetListSubColumnSec() {
    this.props.actionCustomDataReportCustomer.getListColumnSecMasterCustomReportCustomer();
  }

  handleChangeSubColumn(event: any) {
    this.props.actionCustomDataReportCustomer.setSubColumnCdrc(event);
    if (event === '') {
      this.setState({
        ValidateSubColumn: 'error',
      });
    } else {
      this.setState({
        ValidateSubColumn: null,
      });
    }
  }

  handleChangeSubColumnSec(event: any) {
    this.props.actionCustomDataReportCustomer.setSubColumnSecCdrc(event);
    if (event === '') {
      this.setState({
        ValidateSubColumnSec: 'error',
      });
    } else {
      this.setState({
        ValidateSubColumnSec: null,
      });
    }
  }

  handleBlurSubColumn() {
    if (this.props.GetSubColumn === null) {
      this.setState({
        ValidateSubColumn: 'error',
      });
    } else {
      this.setState({
        ValidateSubColumn: null,
      });
    }
  }

  handleBlurSubColumnSec() {
    if (this.props.GetSubColumnSec === null) {
      this.setState({
        ValidateSubColumnSec: 'error',
      });
    } else {
      this.setState({
        ValidateSubColumnSec: null,
      });
    }
  }

  handleChangeReplaceSuccess(event: any) {
    this.props.actionCustomDataReportCustomer.setReplaceSuccessCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateReplaceSuccess: 'error',
      });
    } else {
      this.setState({
        ValidateReplaceSuccess: null,
      });
    }
  }

  handleChangeReplaceReturn(event: any) {
    this.props.actionCustomDataReportCustomer.setReplaceReturnCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateReplaceReturn: 'error',
      });
    } else {
      this.setState({
        ValidateReplaceReturn: null,
      });
    }
  }

  handleChangeReplaceOnProcess(event: any) {
    this.props.actionCustomDataReportCustomer.setReplaceOnProcessCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateReplaceOnProcess: 'error',
      });
    } else {
      this.setState({
        ValidateReplaceOnProcess: null,
      });
    }
  }

  handleChangeReplaceOnHold(event: any) {
    this.props.actionCustomDataReportCustomer.setReplaceOnHoldCdrc(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateReplaceOnHold: 'error',
      });
    } else {
      this.setState({
        ValidateReplaceOnHold: null,
      });
    }
  }

  handleChangeCheckBoxPetik() {
    this.props.actionCustomDataReportCustomer.setCheckBoxPetikCdrc(
      !this.props.GetCheckBoxPetik
    );
  }

  handleChangeCheckBoxOthers() {
    this.props.actionCustomDataReportCustomer.setCheckBoxOthersCdrc(
      !this.props.GetCheckBoxOthers
    );
  }

  handleCloseModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionCustomDataReportCustomer.clearAllCdrc();
    this.setState({
      ValidateHeader: null,
      ValidateFormat: null,
      ValidatePosition: null,
      ValidateValueDefault: null,
      ValidateSourceTable: null,
      ValidateFormatType: null,
      ValidateSourceColumn: null,
      ValidateSourceColumnSec: null,
      ValidateFormatColumn: null,
      ValidateHistoryType: null,
      ValidateDeliveryTo: null,
      ValidateSubColumn: null,
      ValidateSubColumnSec: null,
      ValidateReplaceSuccess: null,
      ValidateReplaceReturn: null,
      ValidateReplaceOnProcess: null,
      ValidateReplaceOnHold: null,
    });
  }

  handleSubmitCustomReport() {
    if (this.props.jenisToggle === 'AddCustomReportDataCustomer') {
      this.props.actionCustomDataReportCustomer.submitAddCdrc();
    } else if (this.props.jenisToggle === 'EditCustomReportDataCustomer') {
      this.props.actionCustomDataReportCustomer.submitEditCdrc();
    }
  }

  render() {
    return (
      <div>
        <FormCustomDataReportCustomerComponent
          visible={this.props.visible}
          jenisToggle={this.props.jenisToggle}
          isLoading={this.props.isLoading}
          LoadingCustomer={this.props.LoadingCustomer}
          TypeLoadingCustomer={this.props.TypeLoadingCustomer}
          GetCustomerName={this.props.GetCustomerName}
          GetHeader={this.props.GetHeader}
          GetFormat={this.props.GetFormat}
          GetPosition={this.props.GetPosition}
          GetValue={this.props.GetValue}
          GetValueDefault={this.props.GetValueDefault}
          GetSourceTable={this.props.GetSourceTable}
          GetFormatType={this.props.GetFormatType}
          GetSourceColumn={this.props.GetSourceColumn}
          GetSourceColumnSec={this.props.GetSourceColumnSec}
          GetListSourceColumn={this.props.GetListSourceColumn}
          GetListSourceColumnSec={this.props.GetListSourceColumnSec}
          GetFormatColumn={this.props.GetFormatColumn}
          GetCharLength={this.props.GetCharLength}
          GetHistoryType={this.props.GetHistoryType}
          GetDeliveryTo={this.props.GetDeliveryTo}
          GetSubColumn={this.props.GetSubColumn}
          GetSubColumnSec={this.props.GetSubColumnSec}
          GetListSubColumn={this.props.GetListSubColumn}
          GetListSubColumnSec={this.props.GetListSubColumnSec}
          GetReplaceSuccess={this.props.GetReplaceSuccess}
          GetReplaceReturn={this.props.GetReplaceReturn}
          GetReplaceOnProcess={this.props.GetReplaceOnProcess}
          GetReplaceOnHold={this.props.GetReplaceOnHold}
          GetCheckBoxPetik={this.props.GetCheckBoxPetik}
          GetCheckBoxOthers={this.props.GetCheckBoxOthers}
          ValidateHeader={this.state.ValidateHeader === 'error' ? 'error' : ''}
          ValidateFormat={this.state.ValidateFormat === 'error' ? 'error' : ''}
          ValidatePosition={
            this.state.ValidatePosition === 'error' ? 'error' : ''
          }
          ValidateValueDefault={
            this.state.ValidateValueDefault === 'minchar'
              ? 'error'
              : '' || this.state.ValidateValueDefault === 'error'
              ? 'error'
              : ''
          }
          ValidateSourceTable={
            this.state.ValidateSourceTable === 'error' ? 'error' : ''
          }
          ValidateFormatType={
            this.state.ValidateFormatType === 'error' ? 'error' : ''
          }
          ValidateSourceColumn={
            this.state.ValidateSourceColumn === 'error' ? 'error' : ''
          }
          ValidateSourceColumnSec={
            this.state.ValidateSourceColumnSec === 'error' ? 'error' : ''
          }
          ValidateFormatColumn={
            this.state.ValidateFormatColumn === 'error' ? 'error' : ''
          }
          ValidateHistoryType={
            this.state.ValidateHistoryType === 'error' ? 'error' : ''
          }
          ValidateDeliveryTo={
            this.state.ValidateDeliveryTo === 'error' ? 'error' : ''
          }
          ValidateSubColumn={
            this.props.GetFormatType !== 'CombineColumn' &&
            this.props.GetFormatType !== 'Priority' &&
            this.state.ValidateSubColumn === 'error'
              ? 'error'
              : ''
          }
          ValidateSubColumnSec={
            this.props.GetFormatType !== 'CombineColumn' &&
            this.props.GetFormatType !== 'Priority' &&
            this.state.ValidateSubColumnSec === 'error'
              ? 'error'
              : ''
          }
          ValidateReplaceSuccess={
            this.state.ValidateReplaceSuccess === 'error' ? 'error' : ''
          }
          ValidateReplaceReturn={
            this.state.ValidateReplaceReturn === 'error' ? 'error' : ''
          }
          ValidateReplaceOnProcess={
            this.state.ValidateReplaceOnProcess === 'error' ? 'error' : ''
          }
          ValidateReplaceOnHold={
            this.state.ValidateReplaceOnHold === 'error' ? 'error' : ''
          }
          helpValidateHeader={
            this.state.ValidateHeader === 'error'
              ? 'Judul kolom wajib diisi'
              : ''
          }
          helpValidatePosition={
            this.state.ValidatePosition === 'error' ? 'Posisi wajib diisi' : ''
          }
          helpValidateFormat={
            this.state.ValidateFormat === 'error' ? 'Format wajib diisi' : ''
          }
          helpValidateValueDefault={
            this.state.ValidateValueDefault === 'minchar'
              ? 'Value default harus lebih dari 3 karakter'
              : '' || this.state.ValidateValueDefault === 'error'
              ? 'Value default wajib diisi'
              : ''
          }
          helpValidateSourceTable={
            this.state.ValidateSourceTable === 'error'
              ? 'Sumber tabel wajib diisi'
              : ''
          }
          helpValidateFormattype={
            this.state.ValidateFormatType === 'error'
              ? 'Format tipe wajib diisi'
              : ''
          }
          helpValidateSourceColumn={
            this.state.ValidateSourceColumn === 'error'
              ? 'Sumber kolom wajib diisi'
              : ''
          }
          helpValidateSourceColumnSec={
            this.state.ValidateSourceColumnSec === 'error'
              ? 'Sumber kolom 2 wajib diisi'
              : ''
          }
          helpValidateFormatColumn={
            this.state.ValidateFormatColumn === 'error'
              ? 'Format kolom wajib diisi'
              : ''
          }
          helpValidateHistoryType={
            this.state.ValidateHistoryType === 'error'
              ? 'Jenis riwayat wajib diisi'
              : ''
          }
          helpValidateDeliveryTo={
            this.state.ValidateDeliveryTo === 'error'
              ? 'Pengiriman ke wajib diisi'
              : ''
          }
          helpValidateSubColumn={
            this.props.GetFormatType !== 'CombineColumn' &&
            this.props.GetFormatType !== 'Priority' &&
            this.state.ValidateSubColumn === 'error'
              ? 'Sub kolom wajib diisi'
              : ''
          }
          helpValidateSubColumnSec={
            this.props.GetFormatType !== 'CombineColumn' &&
            this.props.GetFormatType !== 'Priority' &&
            this.state.ValidateSubColumnSec === 'error'
              ? 'Sub kolom 2 wajib diisi'
              : ''
          }
          helpValidateReplaceSuccess={
            this.state.ValidateReplaceSuccess === 'error'
              ? 'Format sukses wajib diisi'
              : ''
          }
          helpValidateReplaceReturn={
            this.state.ValidateReplaceReturn === 'error'
              ? 'Format return wajib diisi'
              : ''
          }
          helpValidateReplaceOnProcess={
            this.state.ValidateReplaceOnProcess === 'error'
              ? 'Format onprocess wajib diisi'
              : ''
          }
          helpValidateReplaceOnHold={
            this.state.ValidateReplaceOnHold === 'error'
              ? 'Format onhold wajib diisi'
              : ''
          }
          handleChangeHeader={this.handleChangeHeader}
          handleChangePosition={this.handleChangePosition}
          handleChangeValue={this.handleChangeValue}
          handleChangeValueDefault={this.handleChangeValueDefault}
          handleChangeSourceTable={this.handleChangeSourceTable}
          handleChangeFormat={this.handleChangeFormat}
          handleChangeFormatType={this.handleChangeFormatType}
          handleChangeSourceColumn={this.handleChangeSourceColumn}
          handleChangeSourceColumnSec={this.handleChangeSourceColumnSec}
          handleChangeFormatColumn={this.handleChangeFormatColumn}
          handleChangeHistoryType={this.handleChangeHistoryType}
          handleChangeDeliveryTo={this.handleChangeDeliveryTo}
          handleChangeSubColumn={this.handleChangeSubColumn}
          handleChangeSubColumnSec={this.handleChangeSubColumnSec}
          handleChangeReplaceSuccess={this.handleChangeReplaceSuccess}
          handleChangeReplaceReturn={this.handleChangeReplaceReturn}
          handleChangeReplaceOnProcess={this.handleChangeReplaceOnProcess}
          handleChangeReplaceOnHold={this.handleChangeReplaceOnHold}
          handleChangeCheckBoxPetik={this.handleChangeCheckBoxPetik}
          handleChangeCheckBoxOthers={this.handleChangeCheckBoxOthers}
          handleChangeCharLength={this.handleChangeCharLength}
          handleBlurSourceTable={this.handleBlurSourceTable}
          handleBlurFormatType={this.handleBlurFormatType}
          handleBlurFormat={this.handleBlurFormat}
          handleBlurSourceColumn={this.handleBlurSourceColumn}
          handleBlurSourceColumnSec={this.handleBlurSourceColumnSec}
          handleBlurHistoryType={this.handleBlurHistoryType}
          handleBlurDeliveryTo={this.handleBlurDeliveryTo}
          handleBlurSubColumn={this.handleBlurSubColumn}
          handleBlurSubColumnSec={this.handleBlurSubColumnSec}
          handleGetListSourceColumn={this.handleGetListSourceColumn}
          handleGetListSourceColumnSec={this.handleGetListSourceColumnSec}
          handleGetListSubColumn={this.handleGetListSubColumn}
          handleGetListSubColumnSec={this.handleGetListSubColumnSec}
          handleResetFormat={this.handleResetFormat}
          handleCloseModal={this.handleCloseModal}
          handleSubmitCustomReport={this.handleSubmitCustomReport}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  GetListTableCdrc:
    state.Customer.FormCustomerConfigReport.CustomDataReport
      .TableCustomReportDataCustomer,
  GetHeader:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Header,
  GetFormat:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Format,
  GetPosition:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Position,
  GetValue:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Value,
  GetValueDefault:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ValueDefault,
  GetSourceTable:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceTable,
  GetFormatType:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.FormatType,
  GetSourceColumn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceColumn,
  GetSourceColumnSec:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceColumnSec,
  GetListSourceColumn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ListSourceColumn,
  GetListSourceColumnSec:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ListSourceColumnSec,
  GetFormatColumn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .FormatColumn,
  GetCharLength:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CharacterLength,
  GetHistoryType:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .HistoryType,
  GetReplaceSuccess:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceSuccess,
  GetReplaceReturn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceReturn,
  GetReplaceOnProcess:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceOnProcess,
  GetReplaceOnHold:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceOnHold,
  GetDeliveryTo:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.DeliveryTo,
  GetSubColumn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn,
  GetSubColumnSec:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SubColumnSec,
  GetListSubColumn:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ListSubColumn,
  GetListSubColumnSec:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ListSubColumnSec,
  GetCheckBoxPetik:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CheckBoxPetik,
  GetCheckBoxOthers:
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CheckBoxOthers,
  GetCustomerName: state.Customer.DetailCustomer.CustomerName,
  LoadingCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  TypeLoadingCustomer: state.Customer.PropertiesCustomer.TypeLoadingCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionCustomDataReportCustomer: bindActionCreators(
    ActionCustomDataReportCustomer,
    dispatch
  ),
  actionAddCustomReportCustomer: bindActionCreators(
    ActionAddCustomReportCustomer,
    dispatch
  ),
  actionEditCustomReportCustomer: bindActionCreators(
    ActionEditCustomReportCustomer,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomDataReportCustomerContainer);
